export const enErrors = {
  validationErrorSessionToken:
    'Unfortunately this identification session is no longer valid.',
  modelNotFound:
    'We could not retrieve all information neccessary to complete identification.',
  problemWithAuthorization: 'Problem with authorization. Try again later.',
  invalidClient: 'Invalid client',
  couldNotFindPerson: 'We could not retrieve a valid person.',
  couldNotFindMobileNumber:
    'We could not find a valid mobile number to complete the process with.',
  invalidToken:
    'Invalid TAN. A new code is already on its way to you. Please try again.',
  dependencyError:
    'Invalid TAN. A new code is already on its way to you. Please try again.',
  expectationMismatch:
    'Your Identification attempt has expired. \nPlease Try again.',
  invalidTan: 'Invalid TAN submitted.',
  invalidIban: 'Invalid IBAN submitted. Please try again.',
  userDataNotFound: 'User data not found on schufaCreditReport.',
  userDidNotHaveQbit: "User didn't have qbit on CreditReport.",
  jointAccount:
    'Joint accounts are not supported. \nPlease use a different IBAN or continue with video identification.',
  identificationAttemptsExceeded:
    'This identification method is not available for this IBAN. \nPlease try another IBAN or continue with video identification.',
  matchForPersonDataNotFound: {
    generic:
      'You are not eligible for this identification method. Please proceed with video identification to continue your verification process.',
    bankOnly:
      'You are not eligible for this identification method. Please proceed with another method to continue your verification process.',
  },
  previousIdentNotFound:
    'This identification method is not available. \nPlease continue with video identification.',
  personAccountMatchNotFound: {
    generic:
      'This IBAN is not eligible. Please provide a new IBAN to continue your verification process or proceed with video identification instead.',
    bankOnly:
      'This IBAN is not eligible. Please provide a new IBAN to continue your verification process',
  },
  accessByAuthorizedHolder:
    'You do not appear to be the account holder. \nPlease use another IBAN or continue with the video identification.',
  ibanUsageLimitExceeded:
    'You have reached the limit of attempts. \nPlease continue with video identification.',
  errorDuringDownload: 'Error occurred while trying to download a document.',
  paymentError:
    'Unfortunately we could not process the identifcation with the given IBAN.',
  sessionTimeout: 'Unfortuantely your session has timed out. Please try again.',
  tanFailed: 'You have exceeded the maximum number of TAN attempts.',
  personMissingRequiredData: 'An error has occurred. Please contact support.',
  alreadyIdentifiedSuccessfully:
    'You have already successfully identified yourself.',
  loginFailed:
    'You have exceeded the maximum number of login attempts with your bank. \nPlease validate your credentials before trying again',
  techError: 'A technical error occurred. Try again later.',
  transNotPossible:
    'Unfortunately transaction could not be completed. Please try again.',
  invalidSessionOrEnv: 'Please try again.',
  accountSnapshotExpired:
    'The bank login session has expired. \nPlease try again.',
  accountSnapshotFailed:
    'The bank login or transfer has failed. \nPlease try again',
  paymentInitiationExpired:
    'Unfortunately you bank login could not be completed within the expected timeframe. \nPlease try again.',
  expired: 'Your Identification attempt has expired. \nPlease Try again.',
  default: 'An error occurred. Please try again later.',
  expiredSession: 'Please try again.',
  shareError: 'Unfortunately this file cannot be shared.',
  unexpectedError: 'Something went wrong. Please restart the identification.',
  userAbortWhileWaiting: 'Identification was aborted by the user.',
  idNotSupported: 'Identification documents are not supported.',
  noIdentMethodAvailable: 'There is no available method for identification.',
};
